import React from 'react'
import Footer from '../components/Footer'
import HigherEducation from '../components/HigherEducation'
import Navbar from '../components/Navbar'

const highereducation = () => {
  return (
    <div>
        <Navbar/>
        <HigherEducation/>
        <Footer/>

    </div>
  )
}

export default highereducation